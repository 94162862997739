import React from 'react';
import { startOfWeek, endOfWeek, addDays, isToday, format } from 'date-fns';
import { nl } from 'date-fns/locale';
import Loader from 'react-loader-spinner';
import Day from '../components/Day';
import axios from 'axios';
import {toast} from 'react-toastify';

export default class Calendar extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            date: new Date(),
            activities: null,
            hasAllCleaning: false,

            loader: true,
            showCalendar: true,
            showAdd: false,

            calendarAddDate: null,
            selectDaySection: 'VOORMIDDAG',
            selectActivity: 'Eten Geven'
        }
    }

    componentDidMount() {
        this.loadActivities();
    }

    render() {
        return (
            <div className="Calendar">
                {this.renderData()}
            </div>
        );
    }

    renderData() {
        if (this.state.loader) {
            return this.renderLoader();
        } else if (this.state.showCalendar) {
            return this.renderCalendar();
        } else if (this.state.showAdd) {
            return this.renderAdd();
        }
    }

    renderLoader() {
        return (
            <div className="loader">
                <Loader type="Hearts" color="#94284a" height={160} width={160} />
            </div>
        )
    }

    renderCalendar() {
        return (
            <div>
                <div className="buttons">
                    <div className="button" onClick={() => this.addDays(-7)}>
                        Vorige Week
                    </div>
                    <div className="button" onClick={() => this.addDays(7)}>
                        Volgende Week
                    </div>
                </div>

                {(!this.state.hasAllCleaning) ? (
                  <div className="wc-alert">
                      <p>Nog niet alle wekelijkse kuis activiteiten zijn ingevuld!</p>
                  </div>
                ) : null }

                <main className="main">
                    <div className="calendar">
                        {this.renderDays()}
                    </div>
                </main>
            </div>
        )
    }

    renderAdd() {
        return (
            <div>
                <h2>Activiteit toevoegen</h2>
                <br />

                <p className="add-label">Datum</p>
                <input className="input" value={this.formatDate(this.state.calendarAddDate, 'EEEE dd MMMM yyyy')} disabled={true}/>

                <p className="add-label">Moment van de dag</p>
                <select className="select" value={this.state.selectDaySection} onChange={this.changeDaySection.bind(this)}>
                    <option value="VOORMIDDAG">Voormiddag</option>
                    <option value="NAMIDDAG">Namiddag</option>
                    <option value="AVOND">Avond</option>
                </select>

                <p className="add-label">Activiteit</p>
                <select className="select" value={this.state.selectActivity} onChange={this.changeActivity.bind(this)}>
                    <option value="Eten geven">Eten geven</option>
                    <option value="Stallen">Stallen</option>
                    <option value="Wei uitmesten">Wei uitmesten</option>
                    <option value="Rijden">Rijden</option>
                    <option value="Badkamer boven">Badkamer boven</option>
                    <option value="Badkamer beneden">Badkamer beneden</option>
                    <option value="Keuken">Keuken</option>
                    <option value="Eetkamer">Eetkamer</option>
                    <option value="Gang & Gasten-wc">Gang & Gasten-wc</option>
                    <option value="Huis & Tuin">Huis & Tuin</option>
                </select>

                <div className="add-buttons">
                <div className="button" onClick={this.submitAdd.bind(this)}>
                        Toevoegen
                    </div>
                    <div className="button" onClick={this.cancelAdd.bind(this)}>
                        Annuleren
                    </div>
                </div>
            </div>
        )
    }

    changeDaySection(event) {
        this.setState({
            selectDaySection: event.target.value
        });
    }

    changeActivity(event) {
        this.setState({
            selectActivity: event.target.value
        });
    }

    loadActivities() {
        this.setState({
            loader: true
        }, () => {
            const start = format(startOfWeek(this.state.date, { weekStartsOn: 1}), 'yyyy-MM-dd');
            const end = format(endOfWeek(this.state.date, { weekStartsOn: 1}), 'yyyy-MM-dd');

            axios.get('/api/activities?start=' + start + '&end=' + end, { headers: { 'auth-key': this.props.user.key }})
                .then(response => {
                    const activities = {};

                    let hasBadkamerBoven = false;
                    let hasBadkamerBeneden = false;
                    let hasKeuken = false;
                    let hasEetkamer = false;

                    response.data.activities.forEach(activity => {
                        const date = this.formatDate(new Date(activity.day), 'yyyyMMdd');

                        if (!activities[date]) {
                            activities[date] = {
                                "VOORMIDDAG": {},
                                "NAMIDDAG": {},
                                "AVOND": {}
                            }
                        }

                        const daySection = activities[date][activity.day_section];

                        let userActivities = [];

                        if (daySection[activity.user_id]) {
                            userActivities = daySection[activity.user_id];
                        }

                        userActivities.push(activity);
                        activities[date][activity.day_section][activity.user_id] = userActivities;

                        switch (activity.activity) {
                            case "Badkamer boven":
                                hasBadkamerBoven = true;
                                break;
                            case "Badkamer beneden":
                                hasBadkamerBeneden = true;
                                break;
                            case "Keuken":
                                hasKeuken = true;
                                break;
                            case "Eetkamer":
                                hasEetkamer = true;
                                break;
                        }
                    });

                    this.setState({
                        loader: false,
                        showCalendar: true,
                        activities: activities,
                        hasAllCleaning: hasBadkamerBoven && hasBadkamerBeneden && hasKeuken && hasEetkamer
                    });

                }).catch(err => {
                console.log(err);
                toast.error(err.response.data.reason);
                this.setState({
                    loader: false
                })
            })
        })
    }

    renderDays() {
        const days = [];

        const startDate = startOfWeek(this.state.date, { weekStartsOn: 1});

        for (let i = 0; i < 7; i++) {
            const date = addDays(startDate, i);
            days.push(<Day
                key={this.formatDate(date, 'dd-MM-yyyy')}
                dayOfWeek={this.formatDate(date, 'EEEE')}
                date={this.formatDate(date, 'dd/MM/yyyy')}
                onAdd={() => this.addActivity(date)}
                activites={this.state.activities[this.formatDate(date, 'yyyyMMdd')]}
                activeUser={this.props.user.id}
                delete={this.delete.bind(this)}
                className={isToday(date) ? 'today' : ''}
            />);
        }

        return days;
    }

    formatDate(date, dateFormat) {
        return format(date, dateFormat, { locale: nl });
    }

    addDays(days) {
        this.setState({
            date: addDays(this.state.date, days)
        }, () => this.loadActivities());
    }

    addActivity(date) {
        this.setState({
            showCalendar: false,
            showAdd: true,
            calendarAddDate: date
        })
    }

    submitAdd() {
        this.setState({
            loader: true
        }, () => {

            axios.post('/api/activities',
                {
                    day: this.formatDate(this.state.calendarAddDate, 'yyyy-MM-dd'),
                    day_section: this.state.selectDaySection,
                    activity: this.state.selectActivity
                },
                { headers: { 'auth-key': this.props.user.key }})
                .then(() => {
                    toast.success('Activiteit toegevoegd!');
                }).catch(err => {
                console.log(err);
                toast.error(err.response.data.reason);
            }).finally(() => {
                this.setState({
                    showAdd: false,
                    calendarAddDate: null,
                    selectDaySection: 'VOORMIDDAG',
                    selectActivity: 'Eten geven'
                }, () => this.loadActivities());
            })
        });
    }

    cancelAdd() {
        this.setState({
            showAdd: false,
            calendarAddDate: null,
            selectDaySection: 'VOORMIDDAG',
            selectActivity: 'Eten geven'
        }, () => this.loadActivities());
    }

    delete(id) {
        this.setState({
            loader: true
        }, () => {
            axios.delete('/api/activities/' + id,
                { headers: { 'auth-key': this.props.user.key }})
                .then(() => {
                    toast.success('Activiteit verwijderd!');
                }).catch(err => {
                console.log(err);
                toast.error(err.response.data.reason);
            }).finally(() => {
                this.loadActivities();
            })
        });
    }
}