import React from 'react';
import DaySection from './DaySection';

import './Day.css';

class Day extends React.Component {

  constructor(props) {
    super(props);

    let morningFood = false;
    let eveningFood = false;
    let stables = false;

    if (this.props.activites) {

      const morning = this.props.activites['VOORMIDDAG'];
      const userIdsMorning = Object.keys(morning);

      userIdsMorning.forEach(id => {
        const activities = morning[id];
        activities.forEach(activity => {
          if (activity.activity === 'Eten Geven' || activity.activity === 'Eten geven') {
            morningFood = true;
          } else if (activity.activity === 'Stallen') {
            stables = true;
          }
        })
      });

      const afternoon = this.props.activites['NAMIDDAG'];
      const userIdsAfternoon = Object.keys(afternoon);

      userIdsAfternoon.forEach(id => {
        const activities = afternoon[id];
        activities.forEach(activity => {
          if (activity.activity === 'Stallen') {
            stables = true;
          }
        })

      });

      const evening = this.props.activites['AVOND'];
      const userIdsEvening = Object.keys(evening);

      userIdsEvening.forEach(id => {
        const activities = evening[id];
        activities.forEach(activity => {
          if (activity.activity === 'Eten Geven' || activity.activity === 'Eten geven') {
            eveningFood = true;
          } else if (activity.activity === 'Stallen') {
            stables = true;
          }
        })
      });
    }

    this.state = {
      allFound: morningFood && stables && eveningFood
    };
  }

  render() {
    return (
      <div className={this.props.className + " day" + (this.state.allFound ? "" : " missing")}>
        <div className="day-header">
          {this.props.dayOfWeek}
          <br />
          {this.props.date}
          <br />
          <p className="day-add" onClick={this.props.onAdd}> + Activiteit toevoegen</p>
        </div>
        <DaySection daysection={"Voormiddag"} activeUser={this.props.activeUser} delete={this.props.delete} activities={this.props.activites ? this.props.activites['VOORMIDDAG'] : null} />
        <DaySection daysection={"Namiddag"} activeUser={this.props.activeUser} delete={this.props.delete} activities={this.props.activites ? this.props.activites['NAMIDDAG'] : null} />
        <DaySection daysection={"Avond"} activeUser={this.props.activeUser} delete={this.props.delete} activities={this.props.activites ? this.props.activites['AVOND'] : null} />
      </div>
    )
  }
}

export default Day;
