import React from 'react';

import PompPhoto from '../assets/Pomp.jpg';
import PompSchemaPhoto from '../assets/Pomp_Schema.jpg';

export default class Pomp extends React.Component {

    render() {
        return (
            <div className="Pomp">
                <h1>
                    Hoe de pomp te gebruiken?
                </h1>

                <p>
                    Om de pomp achter de piste te gebruiken om de paarden af te spuiten moet je volgende stappen uitvoeren.
                    Gelieve deze altijd in de juiste volgorde te doen!
                </p>

                <div className="photos">
                    <img src={PompPhoto} alt={"Foto pomp"} className="image" />
                    <img src={PompSchemaPhoto} alt={"Foto schema pomp"} className="image" />
                </div>

                <h1>
                    De pomp aan zetten:
                </h1>

                <p>
                    <span className="list-number">1)</span> Zet hendel <span className="pomp-letter">V</span> gelijk met de buis.

                    <br />

                    <span className="list-number">2)</span> Controleer of <span className="pomp-letter">P</span> gelijk met de buis.

                    <br />

                    <span className="list-number">3)</span> Zet rode hendel <span className="pomp-letter">M</span> gelijk met de buis.

                    <br />

                    <span className="list-number">4)</span> Controleer dat alle andere hendels dwars op de buis staan.

                    <br/>

                    <span className="pomp-letter">ENKEL ALS BOVENSTAANDE STAPPEN GEDAAN ZIJN.</span>

                    <br />

                    <span className="list-number">5)</span> Schakelaar in het vak links boven naar rechts draaien zodat de pomp start.

                    <br />

                    <span className="list-number">6)</span> Je kan nu de tuinslang gebruiken. Het kan zijn dat het even duurt voor er water komt.

                    <br />

                    Als dit enkele minuten duurt kan je het best terug afzetten en even bij Walter gaan checken.

                    <br />

                    Van zodra je stop met de spuit te gebruiken gelieve de pomp <span className="pomp-letter">onmiddellijk</span> uit te zetten.
                </p>

                <h1>
                    De pomp uit zetten:
                </h1>

                <p>
                    <span className="list-number">1)</span> Schakelaar links boven terug naar het midden draaien.

                    <br />

                    <span className="list-number">2)</span> Zet rode hendel <span className="pomp-letter">M</span> dwars met de buis.

                    <br />

                    <span className="list-number">3)</span> Zet hendel <span className="pomp-letter">V</span> dwars met de buis.

                    <br />

                    <span className="list-number">4)</span> De tuinslang terug deftig oprollen en binnen zetten en het kot sluiten.
                </p>
            </div>
        );
    }
}