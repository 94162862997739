import React from 'react';

import './DaySection.css';

class DaySection extends React.Component {
  render() {
    return (
      <div className="day-section">
        <div>
          <h2>{this.props.daysection}</h2>
          { this.renderUsers() }
        </div>
      </div>
    )
  }

  renderUsers() {
    if (this.props.activities) {
      let users = [];

      const userIds = Object.keys(this.props.activities);

      userIds.forEach(id => {
        const activities = this.props.activities[id];

        users.push(
          <div>
            <p>{activities[0].username}</p>
            <ul>
              {this.renderActivities(activities)}
            </ul>
          </div>
        )
      });

      return users;
    }
  }

  renderActivities(activities) {
    return activities.map(activity =>
      <li key={activity.id}>
        <span>{activity.activity}</span>
        {activity.user_id === this.props.activeUser ?
          <span className="delete" onClick={() => this.props.delete(activity.id)}>(X)</span>
          : ''}
      </li>)
  }
}

export default DaySection;
