import React from 'react';
import Loader from 'react-loader-spinner';
import axios from 'axios';
import {toast} from 'react-toastify';
import parse from 'html-react-parser';

export default class Medicatie extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loader: true,
            medication: [],
            ownMedication: undefined,
            currentInfo: ''
        }
    }

    componentDidMount() {
        this.loadMedication();
    }

    render() {
        return (
            <div className="medication">
                {this.renderData()}
            </div>
        );
    }

    renderData() {
        if (this.state.loader) {
            return this.renderLoader();
        }

        return this.renderMedication();
    }

    renderLoader() {
        return (
            <div className="loader">
                <Loader type="Hearts" color="#94284a" height={160} width={160} />
            </div>
        )
    }

    renderMedication() {
        return (
            <div>
                <div>
                    { this.renderMedicationList() }
                </div>
                <div>
                    <h1>Jouw medicatie info:</h1>
                    <textarea onChange={this.changeInfo.bind(this)}>
                        { this.state.currentInfo }
                    </textarea>
                    <div className="add-buttons">
                        <div className="button" onClick={this.submitUpdate.bind(this)}>
                            Update
                        </div>
                        <div className="button button-delete" onClick={this.submitDelete.bind(this)}>
                            Delete
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderMedicationList() {
        return this.state.medication.map(medication => (
            <div key={medication.user_key}>
                <h1>{ medication.username }</h1>
                <p>
                    { parse(medication.info) }
                </p>
            </div>
        ))
    }

    changeInfo(event) {
        this.setState({
            currentInfo: event.target.value
        });
    }

    loadMedication() {
        this.setState({
            loader: true
        }, () => {
            axios.get('/api/medication', { headers: { 'auth-key': this.props.user.key }})
                .then(response => {
                    let own = undefined;

                    response.data.medication.forEach(medication => {
                        if (medication.user_id === this.props.user.id) {
                            own = medication;
                        }
                    })

                    this.setState({
                        loader: false,
                        medication: response.data.medication,
                        ownMedication: own,
                        currentInfo: own ? own.info.replaceAll('<br />', '\n') : '',
                    });
                }).catch(err => {
                    console.log(err);
                    toast.error(err.response.data.reason);
                    this.setState({
                        loader: false
                    })
                })
        })
    }

    submitUpdate() {
        this.setState({
            loader: true
        }, () => {

            axios.post('/api/medication',
                {
                    info: this.state.currentInfo.replaceAll("\n", "<br />")
                },
                { headers: { 'auth-key': this.props.user.key }})
                .then(() => {
                    toast.success('Medicatie info aangepast!');
                    this.loadMedication();
                }).catch(err => {
                    console.log(err);
                    toast.error(err.response.data.reason);
                    this.setState({
                        loader: false
                    })
                })
        });
    }

    submitDelete() {
        this.setState({
            loader: true
        }, () => {
            axios.delete('/api/medication/',
                { headers: { 'auth-key': this.props.user.key }})
                .then(() => {
                    toast.success('Medicatie info verwijderd!');
                }).catch(err => {
                    console.log(err);
                    toast.error(err.response.data.reason);
                }).finally(() => {
                    this.loadMedication();
                })
        });
    }
}